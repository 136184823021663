import { useEffect, useRef } from 'react';

/**
 * From The Prophet himself
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (cb, delay) => {
  const savedCb = useRef();

  useEffect(() => {
    savedCb.current = cb;
  }, [cb]);

  useEffect(() => {
    const tick = () => {
      savedCb.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
